const Table_Tests_SubCategories = [
    {
        test_id: 1,
        sub_cat_test_id: 1
    },
    {
        test_id: 1,
        sub_cat_test_id: 6
    },
    {
        test_id: 2,
        sub_cat_test_id: 1
    },
    {
        test_id: 2,
        sub_cat_test_id: 6
    },
    {
        test_id: 3,
        sub_cat_test_id: 1
    },
    {
        test_id: 3,
        sub_cat_test_id: 6
    },
    {
        test_id: 4,
        sub_cat_test_id: 1
    },
    {
        test_id: 4,
        sub_cat_test_id: 6
    },
    {
        test_id: 5,
        sub_cat_test_id: 1
    },
    {
        test_id: 5,
        sub_cat_test_id: 6
    },
    {
        test_id: 6,
        sub_cat_test_id: 1
    },
    {
        test_id: 6,
        sub_cat_test_id: 6
    },
    {
        test_id: 7,
        sub_cat_test_id: 1
    },
    {
        test_id: 7,
        sub_cat_test_id: 6
    },
    {
        test_id: 8,
        sub_cat_test_id: 1
    },
    {
        test_id: 8,
        sub_cat_test_id: 6
    },
    {
        test_id: 9,
        sub_cat_test_id: 1
    },
    {
        test_id: 9,
        sub_cat_test_id: 6
    },
    {
        test_id: 10,
        sub_cat_test_id: 1
    },
    {
        test_id: 10,
        sub_cat_test_id: 6
    },
    {
        test_id: 11,
        sub_cat_test_id: 1
    },
    {
        test_id: 11,
        sub_cat_test_id: 6
    },
    {
        test_id: 12,
        sub_cat_test_id: 1
    },
    {
        test_id: 12,
        sub_cat_test_id: 6
    },
    {
        test_id: 13,
        sub_cat_test_id: 1
    },
    {
        test_id: 13,
        sub_cat_test_id: 6
    },
    {
        test_id: 14,
        sub_cat_test_id: 1
    },
    {
        test_id: 14,
        sub_cat_test_id: 6
    },
    {
        test_id: 15,
        sub_cat_test_id: 2
    },
    {
        test_id: 15,
        sub_cat_test_id: 6
    },
    {
        test_id: 16,
        sub_cat_test_id: 2
    },
    {
        test_id: 16,
        sub_cat_test_id: 6
    },
    {
        test_id: 17,
        sub_cat_test_id: 2
    },
    {
        test_id: 17,
        sub_cat_test_id: 6
    },
    {
        test_id: 18,
        sub_cat_test_id: 2
    },
    {
        test_id: 18,
        sub_cat_test_id: 6
    },
    {
        test_id: 19,
        sub_cat_test_id: 2
    },
    {
        test_id: 19,
        sub_cat_test_id: 6
    },
    {
        test_id: 20,
        sub_cat_test_id: 2
    },
    {
        test_id: 20,
        sub_cat_test_id: 6
    },
    {
        test_id: 21,
        sub_cat_test_id: 2
    },
    {
        test_id: 21,
        sub_cat_test_id: 6
    },
    {
        test_id: 22,
        sub_cat_test_id: 2
    },
    {
        test_id: 22,
        sub_cat_test_id: 6
    },
    {
        test_id: 23,
        sub_cat_test_id: 2
    },
    {
        test_id: 23,
        sub_cat_test_id: 6
    },
    {
        test_id: 24,
        sub_cat_test_id: 2
    },
    {
        test_id: 24,
        sub_cat_test_id: 6
    },
]

export default Table_Tests_SubCategories;